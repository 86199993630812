import {useEffect,useState, useRef} from 'react'
// import { FiChevronLeft, FiChevronRight, FiGrid } from "react-icons/fi";
import TestimonialList from './TestimonialList';


function Testimonial({passTestimonialFlag, btnRef, contactBtnRef, clientBtnRef, aboutBtnRef, footerRef, socialBtnRef}) {
  const testimonialRef = useRef();

useEffect(() => {
    testimonialRef.current.focus();
  }, []);

 //Pop up handling
 useEffect(()=>{
    let handler = (event) => {
        if(!testimonialRef.current.contains(event.target) 
          && !btnRef.current.contains(event.target)
          && !contactBtnRef.current.contains(event.target)
          && !clientBtnRef.current.contains(event.target)
          && !aboutBtnRef.current.contains(event.target)
          && !footerRef.current.contains(event.target)
          && !socialBtnRef.current.contains(event.target)
           )
          {
            setTimeout(()=>{
              passTestimonialFlag(false)  
            },100)
          }
    };
    document.addEventListener("mousedown", handler);

    return()=>{
    document.removeEventListener("mousedown",handler);
    }
})


return (
  <div className='testimonial-ref-box' ref={testimonialRef}>
    <div className="testimonial-window">

    {TestimonialList?.map((item, index)=>
    <div className="testimonial-grid" key={index}>
      
      <div className="testimonial-img-container">
        <img className="testimonial-img" src={item.imgSrc} alt="testimonial-img" />
      </div>

       <div className="testimonial-content">
         <h1>{item.heading}</h1>
         <h3>{item.designation}</h3>

         {/* <q className='quotes'> */}
           {item.paragraph.map((para,i)=>
           <p className='quote-content' key={i}>{para}</p>
           )}
         {/* </q> */}
         
       </div>
       
    </div>  
    )}

    </div>

  </div>
)
}

export default Testimonial