import React from 'react'
import { motion } from "framer-motion"
import "./Logo.css"

function Logo({src, type, visibility=true}) {
    const animation1={
        opacity:1,
        transition:{
            duration:1,
            delay:0
        }
    }

    const animation2={
        opacity:1,
        transition:{
            duration:1,
            delay:0.5
        }
    }

    const animation3={
        opacity:1,
        transition:{
            duration:1,
            delay:1
        }
    }

    const animation4={
        opacity:1,
        transition:{
            duration:1,
            delay:1.5
        }
    }

    const animation5={
        opacity:1,
        transition:{
            duration:1,
            delay:1.9
        }
    }

  return (
    <>
    {(() => {
        if (type === "1") {

        return (
            <motion.img
                initial={{opacity:0}}
                animate={animation1} 
                className={`client-logo ${visibility?"white":""}`} alt="" src={src} />
        )

        } else if (type === "2") {

        return (
            <motion.img
                initial={{opacity:0}}
                animate={animation2} 
                className={`client-logo ${visibility?"white":""}`} alt="" src={src} />
        )

        } else if(type==="3"){

        return (
            <motion.img
                initial={{opacity:0}}
                animate={animation3} 
                className={`client-logo ${visibility?"white":""}`} alt="" src={src} />
        )

        } else if(type==="4"){

            return (
                <motion.img
                    initial={{opacity:0}}
                    animate={animation4} 
                    className={`client-logo ${visibility?"white":""}`} alt="" src={src} />
            )
    
        } else {

            return (
                <motion.img
                    initial={{opacity:0}}
                    animate={animation5} 
                    className={`client-logo ${visibility?"white":""}`} alt="" src={src} />
            )
    
        }

        })()}
    </>
  )
}

export default Logo