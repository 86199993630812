import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import emailjs from "emailjs-com"
import "./Form.css"

function Form() {
    const email_Key = process.env.REACT_APP_EMAIL_KEY
    const service_ID = process.env.REACT_APP_EMAIL_SERVICE_ID
    const template_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID
    // const template_ID_2 = process.env.REACT_APP_EMAIL_TEMPLATE_ID_2

    const [errorPopup, setErrorPopup] = useState(false)
    const [successPopup, setSuccessPopup] = useState(false)

    const [formData, setFormData] = useState({
        user_name:'',
        user_email:'',
        phone:'',
        eventType:'',
        moreInfo:''
    })

    const {user_name, user_email, phone, eventType, moreInfo} = formData
  
    /////////////////////////////////////////////////
    //////// Functions /////////////////////////////
    ////////////////////////////////////////////////

    const onChange = (e) => {
        setFormData((prevState)=>({
            ...prevState, 
            [e.target.name] : e.target.value,
        }))
        // console.log(`Entered onChange`)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        
        if(user_name==='' || user_email===''
        || phone==='' || eventType==='' || moreInfo===''){
            // console.log(`Empty fields`)
            setErrorPopup(true)
            setTimeout(()=>{
                setErrorPopup(false)
              },2000)
        }else{

            // const userData = {
            //     user_name, user_email, phone, eventType, moreInfo
            // }
            // console.log(`userData: ${JSON.stringify(userData,null,4)}`)

            // setSuccessPopup(true)
            //     setTimeout(()=>{
            //         setSuccessPopup(false)
            //     },2000)

            emailjs.sendForm(service_ID,
                template_ID, 
                e.target,
                email_Key)
                .then(res=>{
                    // console.log(res)
                        setSuccessPopup(true)
                        setTimeout(()=>{
                            setSuccessPopup(false)
                        },4000)
                }).catch(err=> console.log(err))
        }
    }

  return (
    <>
    <div className='form-container'>
        <form onSubmit={onSubmit}>
            
            <div className="form-grid">
                <div className="form-group">
                    <label>Name</label>
                    <input type = "text" 
                        className="form-control" 
                        name = 'user_name'
                        id = 'user_name'
                        value = {user_name} 
                        placeholder= 'Name'
                        onChange = {onChange} />
                </div>

                <div className="form-group">
                    <label>E-mail</label>
                    <input type="email" 
                        className="form-control" 
                        name='user_email'
                        id='user_email' 
                        value={user_email} 
                        placeholder='E-mail'
                        onChange={onChange} />
                </div>

                <div className="form-group">
                    <label>Phone</label>
                    <input type="text" 
                        className="form-control" 
                        name='phone'
                        id='phone' 
                        value={phone} 
                        placeholder='Phone number'
                        onChange={onChange}
                        onKeyPress={(e) => !/[0-9,.,+, ]/.test(e.key) && e.preventDefault()}
                         />
                </div>

                <div className="form-group">
                    <label>Event</label>
                    <input type="text" 
                        className="form-control" 
                        name='eventType'
                        id='eventType' 
                        value={eventType} 
                        placeholder='Event Type'
                        onChange={onChange} />
                </div>

                <div className="form-group form-group-textarea">
                    <label>Tell us more</label>
                    <textarea type="text" rows="4"
                        className="form-control form-control-textarea" 
                        name='moreInfo'
                        id='moreInfo' 
                        value={moreInfo} 
                        placeholder='Tell us more about your dream event'
                        onChange={onChange} />
                </div>
            </div>
            
            
            <div className="form-group">
                <button type="submit" className="submit-btn">
                    Submit
                </button>
            </div>

        </form>
    </div>

    <AnimatePresence>
        {errorPopup && 
        // <div className="error-container">
            <motion.div className="error-content"
                initial={{opacity:0}} 
                animate={{opacity:1}}
                exit={{opacity:0}}
                transition={{duration:0.8}}
            >
                Please fill all the fields
            </motion.div>
        // </div>
        }
    </AnimatePresence>

    <AnimatePresence>
        {successPopup && 
        // <div className="error-container">
            <motion.div className="error-content"
                initial={{opacity:0}} 
                animate={{opacity:1}}
                exit={{opacity:0}}
                transition={{duration:0.8}}
            >
                Thanks for showing interest in us!
            </motion.div>
        // </div>
        }
    </AnimatePresence>
    </>
  )
}

export default Form