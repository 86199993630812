import { useEffect, useState, useRef } from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import Frame from '../../components/Frame/Frame'
import GalleryLoader from '../../components/Loader/GalleryLoader'

function Sunburn({passNavbarFlag, passFooterFlag, passPrevFooterFlag}) {
  const navigate = useNavigate()

  const ref=useRef(null);

  const imgSrc= "https://ik.imagekit.io/qljqw3tvn/tr:w-1800w/Rashi/Sunburn.jpg"
  // const imgSrc = "./images/Sunburn.jpg"

  const [blurBG, setBlurBG] = useState(false)
  const [check, setCheck] = useState(false)
  const [navFlag, setNavFlag] = useState(false)


  const handleNavigationAnimation =()=>{
    if(!blurBG){
      setNavFlag(true)
      setTimeout(()=>{
        navigate('gallery')
      },1000)
    }
  }

  const handleKeyDown = event => {
    if(event.key==="Enter"){
      handleNavigationAnimation()
    }
  };

  /////////////////////////////////////////////////
  //////// Hooks /////////////////////////////////
  ////////////////////////////////////////////////
  
  //For Rashi Logo Key Press Event
  useEffect(() => {
      ref.current.focus();
    }, []);

  //For page transition animation
  useEffect(()=>{
    if(passPrevFooterFlag){
      setCheck(true)
      setTimeout(()=>{
        setCheck(false)
      },100)
    }
  },[passPrevFooterFlag])

  //For smooth component render animation
  useEffect(()=>{
    if( (!passNavbarFlag && !passFooterFlag && !check)){
      setTimeout(()=>{
        setBlurBG(false)
      },400)
    }
    else if(check && !passFooterFlag){
      setBlurBG(false)
    }
    else {
      setBlurBG(true)
    }
  },[passNavbarFlag, passFooterFlag, check])

  return (
    <>
      <div className='container'>
        <Frame src= {imgSrc} type={blurBG} />
        <motion.div className={`r-dot ${blurBG?"r-dot-disable":""}`} ref={ref} tabIndex={-1} onKeyDown={handleKeyDown}
            animate={{filter:blurBG?"blur(15px) brightness(45%)":"blur(0px) brightness(100%)"}}
            transition={{duration:1}}
            style={{top:"48%", left:"51%"}}>
              <img src="./images/R.png" alt="" className={`r ${blurBG?"r-disable":""}`}
               onClick={()=>handleNavigationAnimation()} />
          </motion.div>

        {navFlag &&
        <GalleryLoader /> }
      </div>
    </>
  )
}

export default Sunburn