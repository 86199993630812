import { useEffect } from 'react'
import "./About.css"

function AboutMobile({passAboutFlag, divRef, btnRef, clientBtnRef, contactBtnRef, testimonialBtnRef, footerRef, socialBtnRef}) {

    //Pop up handling
  useEffect(()=>{
        let handler = (event) => {
            if(!divRef.current.contains(event.target) 
            && !btnRef.current.contains(event.target)
            && !contactBtnRef.current.contains(event.target)
            && !clientBtnRef.current.contains(event.target)
            && !testimonialBtnRef.current.contains(event.target)
            && !footerRef.current.contains(event.target)
            && !socialBtnRef.current.contains(event.target)
            )
            {
                // passMobileFlag(false)
                // console.log(`aboutClicked`)
                passAboutFlag(false)
                
            }
        };
        document.addEventListener("mousedown", handler);

        return()=>{
        document.removeEventListener("mousedown",handler);
        }
    })

  return (
    <>
       <p>Rashi Entertainment is a trailblazer in the event and entertainment industry in India, with over two decades of experience in crafting unforgettable experiences.</p>
       <p> Established in 1999, we identified the significance of every detail that leads to making an event a success and put together a team that is dedicated to handholding ideas to their infinite. Our distinctive expertise in aesthetic command, coordination, design, and management has earned us the recognition as the go-to event planners for luxury weddings in India. As storytellers at heart, we strive to infuse every decor we plan with a meaningful story that resonates with our clients' vision. Our team of talented artists, managers, and technicians are all passionate listeners, ambitious professionals, and unfathomably hardworking.</p>
       <p> Trust Rashi Entertainment to bring your dream event to life with unparalleled creativity, attention to detail, and flawless execution.</p>
    </>
  )
}

export default AboutMobile