const SubHeadingList = [{
    category:"weddings",
    imageURL:"https://ik.imagekit.io/qljqw3tvn/tr:w-1900/Rashi/Weddings.jpg",
    subH:[`We specialize in creating breathtaking, luxurious weddings that are truly unforgettable. From intimate gatherings to grand celebrations, we bring your vision to life with impeccable attention to detail, personalized service, and exquisite style. With a reputation for excellence, we are proud to be one of the top wedding planning companies in the country, delivering unparalleled experiences for couples and their guests. Let us make your dream wedding a reality.`]
},{ 
    category:"social",
    imageURL:"https://ik.imagekit.io/qljqw3tvn/tr:w-1920w/Rashi/Social.jpg",
    subH:[`We are renowned for curating extraordinary and unforgettable social events that are the talk of the town. Our team of experienced planners and designers go above and beyond to ensure that every event is flawlessly executed with the highest level of luxury, sophistication, and style. Whether you're planning an anniversary celebration, birthday party, or any other special occasion, we bring your vision to life with meticulous attention to detail and exceptional service. Trust us to make your social event the topmost and the best in the country.`]
},{
    category:"corporate",
    imageURL:"https://ik.imagekit.io/qljqw3tvn/tr:w-1600/Rashi/Corporate.jpg",
    subH:[`Rashi Entertainment specializes in creating exceptional and unforgettable corporate events for the best and biggest clients all across the country. With our experienced team of planners and designers, we bring your vision to life with impeccable attention to detail, personalized service, and exquisite style. From product launches and conferences to gala dinners and award ceremonies, we ensure that every aspect of your event is flawlessly executed, making it the topmost and the best in the industry. Trust us to deliver a corporate event that exceeds your expectations.`,]
},{
    category:"government",
    imageURL:"https://ik.imagekit.io/qljqw3tvn/tr:w-1920w/Rashi/Govt.jpg",
    subH:[`Rashi Entertainment specializes in creating exceptional and unforgettable government events all across the country. With our extensive experience and expertise, we have had the privilege of working with some of the biggest political parties in the country. From inaugurations to summits, we are committed to flawlessly executing every aspect of your event with impeccable attention to detail, personalized service, and exquisite style. Trust us to deliver a government event that leaves a lasting impact.`],
},{
    category:"sunburn",
    imageURL:"https://ik.imagekit.io/qljqw3tvn/tr:w-1800w/Rashi/Sunburn.jpg",
    subH:[`At Rashi Entertainment, we take immense pride in our partnership with Sunburn from 2017-2021. As collaborators, we delivered some of the highest quality concerts that the country has ever witnessed, featuring renowned artists like DJ Snake, Wiz Khalifa, DVLM, Alan Walker, Above and Beyond, KSHMR, and Diljit Dosanjh. Our team of experienced planners and designers worked tirelessly to ensure that every detail of the concert was flawlessly executed, from the sound and lighting to the overall ambiance of the event. With our commitment to excellence, we brought the best of the music industry to our audiences, leaving them with unforgettable memories. Trust us to bring the same level of dedication and expertise to your event.`,
           `SUNBURN - A PROPERTY OF PERCEPT LIVE | CO-PROMOTED BY RASHI ENTERTAINMENT`],
},]

export default SubHeadingList