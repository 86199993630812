import { useState, useEffect, useRef }from 'react'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram} from "react-icons/fa"
import About from '../../pages/About/About'
import Testimonial from '../../pages/Testimonial/Testimonial'
import Contact from '../../pages/Contact/Contact'
import Options from '../OptionsPopup/Options'
import Client from '../../pages/Clients/Client'
import ImageSlider from '../ImageSlider/ImageSlider'

import "./Navbar.css"
import "../Footer/Footer.css"
import "../OptionsPopup/Options.css"
import "../../pages/About/About.css"
import "../../pages/Testimonial/Testimonial.css"
import "../../pages/Contact/Contact.css"
import '../../pages/Clients/Client.css'
import PathList from '../PathList'

function Navbar({passNavbarFlag, passModalFlag,
   passPrevModalFlag, passImageSliderFlag,
   passImageList, passCurrentImageIndex}) {
  
  const navigate = useNavigate()
  // const params = useParams()
  
  const aboutBtnRef = useRef();
  const aboutDivRef = useRef();
  
  const testimonialBtnRef = useRef();
  const testimonialDivRef = useRef();
  
  const contactDivRef = useRef();
  const contactBtnRef = useRef();
  
  const clientDivRef = useRef();
  const clientBtnRef = useRef();
  
  const footerRef = useRef();
  const socialBtnRef = useRef();

  const [modalOpen, setModalOpen] = useState(false)

  const [navbarFlag, setNavbarFlag]=  useState(false)

  const [aboutFlag, setAboutFlag]=  useState(false)
  const [testimonialFlag, setTestimonialFlag]=  useState(false)
  const [contactFlag, setContactFlag]=  useState(false)
  const [clientFlag, setClientFlag]=  useState(false)
  const [prevOptionFlag, setPrevOptionFlag] = useState(false)
  const [imgSliderFlag, setImgSliderFlag] = useState(false)

  const [darkBG, setDarkBG] = useState(true)

  /////////////////////////////////////////////////
  //////// Functions /////////////////////////////
  ////////////////////////////////////////////////

  const handleHomeClick=()=>{
    setImgSliderFlag(false)
    navigate('/')
  }

  const handleAbtClick=()=>{
    if(!(modalOpen || imgSliderFlag)){
      setClientFlag(false)
      setContactFlag(false)
      setTestimonialFlag(false)
      // setTimeout(()=>{
        setAboutFlag(!aboutFlag)
      // },410)
      
    }
  }

  const handleTestimonialClick=()=>{
    if( !(modalOpen || imgSliderFlag)){
      setTestimonialFlag(!testimonialFlag)
      // console.log(`Testimonial toggle`)
      setAboutFlag(false)
      setContactFlag(false)
      setClientFlag(false)
    }
  }

  const handleContactClick=()=>{
    if( !(modalOpen || imgSliderFlag)){
      setAboutFlag(false)
      setClientFlag(false)
      setTestimonialFlag(false)
      // setTimeout(()=>{
        setContactFlag(!contactFlag)
      // },410)
      
    }
  }

  const handleFooterClick=()=>{
    if( !(aboutFlag || contactFlag || clientFlag || testimonialFlag )){
      setModalOpen(true)
      setPrevOptionFlag(false)
      
      setAboutFlag(false)
      setClientFlag(false)
      setContactFlag(false)
      setTestimonialFlag(false)
    }
  }

  const handleClientClick =()=>{
    if( !( modalOpen || imgSliderFlag)){
      setClientFlag(!clientFlag)
      setAboutFlag(false)
      setContactFlag(false)
      setTestimonialFlag(false)
    }
  }

  /////////////////////////////////////////////////
  //////// Hooks /////////////////////////////////
  ////////////////////////////////////////////////

    //Dark Background Logic
    useEffect(()=>{ 
      const paths = PathList.map((item)=> "/"+item.path)
      // console.log(`paths:${JSON.stringify(paths, null,4)}`)
      // console.log(window.location.pathname)
      if( (paths.includes(window.location.pathname) || window.location.pathname === "/" )){
        setDarkBG(true)
        // console.log(`darkBg:${false}`)
      }else{
        setDarkBG(false)
        // console.log(`darkBg:${false}`)
      }
    },[window.location.pathname])

  useEffect(()=>{
    passNavbarFlag(navbarFlag)
    // console.log(`navbarFlag:${navbarFlag}`)
  },[navbarFlag, passNavbarFlag])

  useEffect(()=>{
    setNavbarFlag(aboutFlag || contactFlag || clientFlag || testimonialFlag || imgSliderFlag )
    // console.log(aboutFlag || contactFlag || clientFlag || testimonialFlag || imgSliderFlag || darkBG)
  },[aboutFlag, contactFlag, clientFlag, testimonialFlag, imgSliderFlag])

  useEffect(()=>{
    passModalFlag(modalOpen)
  },[modalOpen,passModalFlag])

  useEffect(()=>{
    passPrevModalFlag(prevOptionFlag)
  },[prevOptionFlag, passPrevModalFlag])

  useEffect(()=>{
    setImgSliderFlag(passImageSliderFlag)
  },[passImageSliderFlag])

  return (
    <>
    <div className={`navbar-container ${(darkBG||navbarFlag||modalOpen)?"navbar-dark-container":""}`} >
    {/* {(darkBG||navbarFlag||modalOpen)?  */}
    <div className="fake-backdrop"></div>
    
    </div>

    <div className={`navbar-container ${(darkBG||navbarFlag||modalOpen)?"navbar-dark-container":""}`}>
        
        <div className="nav-item">
            
            <div className={`btn ${(modalOpen || imgSliderFlag) ?"disabled":""}`}
              onClick={()=>handleAbtClick()}
              ref={aboutBtnRef} >
                About
            </div>            

            <div className={`btn  ${ ( modalOpen || imgSliderFlag) ?"disabled":""}`}
              onClick={()=>handleTestimonialClick()}
              ref={testimonialBtnRef} >
                Testimonials
            </div>
        
        </div>
        
        <div className="nav-item">
            <div className={`btn stable`}
              onClick={()=> handleHomeClick()}>
                Rashi Entertainment
            </div>
        </div>
        
        <div className="nav-item">
            
            <div className={`btn ${(modalOpen || imgSliderFlag) ?"disabled":""}`}
              onClick={()=>handleClientClick()}
              ref={clientBtnRef}>
                Clients
            </div>

            <div className={`btn ${( modalOpen || imgSliderFlag) ?"disabled":""}`} 
              onClick={()=> handleContactClick()} 
              ref={contactBtnRef}
            >
              Contact
            </div>
        </div>


        <AnimatePresence>
        {aboutFlag && 
          <motion.div  className="content-box" ref={aboutDivRef}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1}}
          exit={{ opacity:0}}
          >
            <About passAboutFlag={setAboutFlag}
              btnRef={aboutBtnRef} 
              contactBtnRef={contactBtnRef}
              clientBtnRef={clientBtnRef}
              testimonialBtnRef={testimonialBtnRef}
              divRef={aboutDivRef}
              footerRef={footerRef}
              socialBtnRef={socialBtnRef}
            /> 
           </motion.div> }
          
        </AnimatePresence >

        <AnimatePresence>
        {testimonialFlag && 
          <motion.div  className="testimonial-container" ref={testimonialDivRef}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1}}
          exit={{ opacity:0}}
          >
            <Testimonial passTestimonialFlag={setTestimonialFlag}
              btnRef={testimonialBtnRef} 
              contactBtnRef={contactBtnRef}
              clientBtnRef={clientBtnRef}
              aboutBtnRef={aboutBtnRef}
              socialBtnRef={socialBtnRef}
              footerRef={footerRef}
            /> 
           </motion.div> }
          
        </AnimatePresence >
        
        <AnimatePresence>
        {contactFlag && 
        <motion.div  className="contact-container" ref={contactDivRef}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1}}
          exit={{ opacity:0}}
        >
          <Contact passContactFlag={setContactFlag} 
            btnRef={contactBtnRef}
            aboutBtnRef={aboutBtnRef}
            clientBtnRef={clientBtnRef}
            testimonialBtnRef={testimonialBtnRef}
            footerRef={footerRef}
            socialBtnRef={socialBtnRef}/> 
        </motion.div>  }
        </AnimatePresence>

        <AnimatePresence>
        {clientFlag && 
        <motion.div  className="client-container" ref={clientDivRef}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1}}
          exit={{ opacity:0}}
        >
          <Client passClientFlag={setClientFlag} 
            btnRef={clientBtnRef}
            aboutBtnRef={aboutBtnRef}
            contactBtnRef={contactBtnRef}
            testimonialBtnRef={testimonialBtnRef}
            footerRef={footerRef}
            socialBtnRef={socialBtnRef}/> 
        </motion.div>  }
        </AnimatePresence>
        
    </div>

    <AnimatePresence >
    {imgSliderFlag && 
      <ImageSlider 
        passImageSliderFlag={setImgSliderFlag} 
        imageList={passImageList} 
        currentIndex={passCurrentImageIndex}
        />}
    </AnimatePresence>
    
    <div className="transparent-container">
      <div className={`footer-container ${darkBG?"footer-dark":""}`}>
        <div className="item"
            ref={footerRef}
            >
              
            {!modalOpen &&
                <motion.div className={`btn-footer ${(aboutFlag 
                  || contactFlag || clientFlag || testimonialFlag)?"disabled":""}`}
                initial={{opacity:0}}
                animate={{opacity:1}}
                exit={{opacity:0}}
                onClick={()=>handleFooterClick()}
                ref={footerRef} >
                  Explore
                </motion.div> 

                
            }

            <AnimatePresence>
            {modalOpen &&
                <motion.div className='popup-container'
                animate={{y:0,opacity:1}}
                exit={{y:50, opacity:0}}
                transition={{duration:0.6}}>
                  
                  <Options 
                    passOptionFlag={setModalOpen} 
                    aboutBtnRef={aboutBtnRef}
                    contactBtnRef={contactBtnRef}
                    clientBtnRef={clientBtnRef}
                    testimonialBtnRef={testimonialBtnRef}
                    socialBtnRef={socialBtnRef}
                    passOptionWasOpenFlag={setPrevOptionFlag} 
                    />
                
                </motion.div>
                }
            </AnimatePresence>
        </div>

        <div className="item">
                <motion.div className={`btn-footer ${darkBG?"":"btn-dark"}`}
                  initial={{opacity:0}}
                  animate={{opacity:1}}
                  exit={{opacity:0}}
                  style={{fontSize:"1rem"}}
                  ref={socialBtnRef} 
                  >
                    <a href="https://www.instagram.com/rashi.entertainment/"  target="_blank" rel="noreferrer">
                      <FaInstagram className={`social-btn`}/>
                    </a>

                    <a href="https://www.youtube.com/channel/UC0xwYBjgvNOhP-DuNiW1pxw"  target="_blank" rel="noreferrer">
                      <FaYoutube className={`social-btn`}/>
                    </a>

                    <a href="https://www.facebook.com/rashientertainment/"  target="_blank" rel="noreferrer">
                      <FaFacebookF className={`social-btn`}/>
                    </a>

                    {/* <a href="https://twitter.com/RashiEnt"  target="_blank" rel="noreferrer" style={{ zIndex:2}}>
                      <FaTwitter className={`social-btn`}/>
                    </a> */}


                </motion.div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Navbar