import { useEffect, useState, useRef } from 'react'
import { FiChevronLeft, FiChevronRight, FiX } from "react-icons/fi";
import { motion } from 'framer-motion'

import AwardsList from './AwardList'
import "./Awards.css"

function Awards({passNavbarFlag, passFooterFlag, passPrevFooterFlag}) {

  const imgSrc= "https://ik.imagekit.io/qljqw3tvn/tr:w-1800w/Rashi/Media.jpg"

  const [blurBG, setBlurBG] = useState(false)
  const [check, setCheck] = useState(false)
  const currentIndex = 0;

  const [current, setCurrent] = useState((typeof currentIndex)==="undefined"? 0:currentIndex)

  const ref=useRef(null);

  //Functions 
  const prevSlide=()=>{
      setCurrent(current === 0 ? AwardsList.length-1: current-1)
  }

  const nextSlide=()=>{
      setCurrent(current === AwardsList.length -1 ? 0: current+1)
  }

  const handleKeyDown = event => {
      if(event.key==="ArrowRight"){
          nextSlide()
      }else if(event.key==="ArrowLeft"){
          prevSlide()
      }
    };

  //Slider Focus
  useEffect(() => {
      ref.current.focus();
    }, []);

  //For page transition animation
  useEffect(()=>{
    if(passPrevFooterFlag){
      setCheck(true)
      setTimeout(()=>{
        setCheck(false)
      },100)
    }
  },[passPrevFooterFlag])

  //For smooth component render animation
  useEffect(()=>{
    if( (!passNavbarFlag && !passFooterFlag && !check)){
      setTimeout(()=>{
        setBlurBG(false)
      },400)
    }
    else if(check && !passFooterFlag){
      setBlurBG(false)
    }
    else {
      setBlurBG(true)
    }
  },[passNavbarFlag, passFooterFlag, check])

  return (
    <motion.div className="container awards-container"  ref={ref} tabIndex={-1} onKeyDown={handleKeyDown}
      animate={{filter:blurBG?"blur(15px) brightness(45%)":"blur(0px) brightness(100%)"}}
      transition={{duration:0.3}}>
         
  
        <div className="award-template-title" >
            <motion.h1
              initial={{opacity:0, y:-200}}
              animate={{opacity:1, y:0}}
              transition={{duration:0.8}} >
             Awards
            </motion.h1> 
        </div>

        <div className="award-content">
          
        
        <FiChevronLeft className='award-slider-icon left-icon-award'
            onClick={()=>prevSlide()}
        />
        <FiChevronRight className='award-slider-icon right-icon-award'
            onClick={()=>nextSlide()}
        />

        <div className='award-grid'>
        {AwardsList.map((imageData, index)=>{
            return(
             
              <>
              {/* <div className={`${index===current? "award-img-box ":"hidden"}`}> */}
              <motion.img className={`${index===current? "award-img ":"hidden"}`}
                initial={{opacity:0}}
                animate={{opacity:1}}
                exit={{opacity:0}}
                transition={{duration:0.6, ease:"linear"}}    
                src={imageData.src} alt=""
                key={index} />
              {/* </div> */}
              <div className={`${index===current? "award-name-box ":"hidden"}`}>

              <h1 className={`award-name ${index===current? "":"hidden"}`}>{imageData.name}</h1>
              </div>
              </>
             
              )
              })}
        </div>
        
        </div>

        <div className="copyright-footer" style={{color:"white"}}> © 2023 Rashi Entertainment Pvt. Ltd. All Rights Reserved </div>
    </motion.div>
  )
}

export default Awards