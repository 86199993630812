const PathList= [
    {
        title: "Weddings",
        path: "weddings/gallery"
    },
    {
        title: "Social",
        path: "social/gallery"

    },
    {
        title: "Sunburn",
        path: "sunburn/gallery"
    },
    {
        title: "Corporate",
        path: "corporate/gallery"
    },
    // {
    //     title: "Artist Management",
    //     path: "artist-mangement"
    // },
    {
        title: "Government",
        path:"government/gallery"
    },
    {
        title: "Media",
        path:"media"
    },
    {
        title: "Awards",
        path:"awards"
    },
    ]

export default PathList