import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

import MediaList from './MediaList'
import "./Media.css"

function Media({passNavbarFlag, passFooterFlag, passPrevFooterFlag}) {

  const imgSrc= "https://ik.imagekit.io/qljqw3tvn/tr:w-1800w/Rashi/Media.jpg"

  const [blurBG, setBlurBG] = useState(false)
  const [check, setCheck] = useState(false)

  //For page transition animation
  useEffect(()=>{
    if(passPrevFooterFlag){
      setCheck(true)
      setTimeout(()=>{
        setCheck(false)
      },100)
    }
  },[passPrevFooterFlag])

  //For smooth component render animation
  useEffect(()=>{
    if( (!passNavbarFlag && !passFooterFlag && !check)){
      setTimeout(()=>{
        setBlurBG(false)
      },400)
    }
    else if(check && !passFooterFlag){
      setBlurBG(false)
    }
    else {
      setBlurBG(true)
    }
  },[passNavbarFlag, passFooterFlag, check])

  return (
    <motion.div className="container media-container" 
      // style={{backgroundColor:"#e63946"}}
      // style={{backgroundColor:"gray"}}
      animate={{filter:blurBG?"blur(15px) brightness(45%)":"blur(0px) brightness(100%)"}}
      transition={{duration:0.3}}>
         {/* <Frame src= {imgSrc} type={blurBG}/> */}
        
        {MediaList.length===0?
         <div className='error404'>
          <h1 style={{color:"black", fontSize:"2rem"}}>404</h1>
          <h3 style={{color:"gray", marginBottom:"5vh"}}>Page not Found</h3>
          
        </div>
         :
         <>
        <div className="media-title"
        >
            <motion.h1
              initial={{opacity:0, y:-200}}
              animate={{opacity:1, y:0}}
              transition={{duration:0.8}}
               >
              Media Coverage
            </motion.h1> 
            
            <motion.div
             className="media-subscript"
             style={{color:"#f1faee"}}
             initial={{opacity:0}}
             animate={{opacity:1}}
             transition={{duration:1.2}}>
              Empowering Industry by Great Leadership
            </motion.div>
        </div>

        <div className="media-content">
          
        
          <div className="media-wrapper">
            {MediaList.map((coverItem)=>
              <a className='link-tag'
               href={coverItem.link}  target="_blank" rel="noreferrer">
              
              <div className='media-item'
              key={coverItem.embedID}>
                {/* <div className="media-card"> */}

                  <div className="media-img">
                  
                    <img src={coverItem.imgSrc} alt="" />
                  
                  </div>
                    <h3>{coverItem.title}</h3>
              </div>
              
              </a>
            )}
          </div>
        </div>
        </>}
        <div className="copyright-footer" style={{color:"white"}}> © 2023 Rashi Entertainment Pvt. Ltd. All Rights Reserved </div>
    </motion.div>
  )
}

export default Media