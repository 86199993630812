const MediaList = [{
    title:`NDTV - HUM LOG, IMPACT OF CORONA VIRUS – BY MR. RAJEEV JAIN`,
    link:"https://www.youtube.com/watch?v=Nj0qZzpvN6U",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/NDTV_-_HUM_LOG__IMPACT_OF_CORONA_VIRUS___BY_MR._RAJEEV_JAIN_wqMMhXTAz.jpeg?updatedAt=1679491140379",
},
{
    title:`NDTV - WE THE PEOPLE, CORONA OUTBREAK IN INDIA – BY MR. RAJEEV JAIN `,
    link:"https://www.youtube.com/watch?v=BJDaxeZ7qNQ",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/NDTV_-_WE_THE_PEOPLE__CORONA_OUTBREAK_IN_INDIA___BY_MR._RAJEEV_JAIN_lgriDLW_Z.jpeg?updatedAt=1679491140204",
},
{
    title:`BE A PART OF THE BIGGEST PLATFORM FOR THE EVENT INDUSTRY – BY MR. RAJEEV JAIN `,
    link:"https://www.youtube.com/watch?v=Ul62jpvCN2I ",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/BE_A_PART_OF_THE_BIGGEST_PLATFORM_FOR_THE_EVENT_INDUSTRY___BY_MR._RAJEEV_JAIN_chTvN3lKbu.jpeg?updatedAt=1679491138829",
},
{
    title:`EEMA RAJEEV JAIN & SANJEEV PASRICHA `,
    link:"https://www.youtube.com/watch?v=fPZyO86OzW8 ",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/EEMA_RAJEEV_JAIN___SANJEEV_PASRICHA_ZF89bqZIMB.jpeg?updatedAt=1679491136502",
},
{
    title:`EEMA SABBAS JOSEPH AND RAJEEV JAIN `,
    link:"https://www.youtube.com/watch?v=KahaZgZxzCk ",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/EEMA_SABBAS_JOSEPH_AND_RAJEEV_JAIN_FKVXOjfr3.jpeg?updatedAt=1679491136521",
},
{
    title:`FOND MEMORIES OF EEMA 'S INCEPTION - BY MR. RAJEEV JAIN`,
    link:"https://www.youtube.com/watch?v=4mFjoaHGeuU ",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/FOND_MEMORIES_OF_EEMA__S_INCEPTION_-_BY_MR._RAJEEV_JAIN_tbgny2TzXa.jpeg?updatedAt=1679491138820",
},
{
    title:`TRAVTALK DIGITAL CONCLAVE – BY MR. RAJEEV JAIN`,
    link:"https://www.facebook.com/118429274893247/posts/2964807766922036/?vh=e&d=n",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/TRAVTALK_DIGITAL_CONCLAVE___BY_MR._RAJEEV_JAIN_d45mvo35j.jpeg?updatedAt=1679491142439",     
},
{
    title:`IMWF 2019 INTERVIEW - BY MR. RAJEEV JAIN`,
    link:"https://www.youtube.com/watch?v=19VVgLh-_aw ",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/IMWF_2019_INTERVIEW_-_BY_MR._RAJEEV_JAIN_KHo-wqOTH.jpeg?updatedAt=1679491136537",
},
{
    title:`MICE TALK – BY MR. RAJEEV JAIN `,
    link:"http://micetalk.com/the-business-of-weddings-is-here-to-stay/",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/MICE_TALK___BY_MR._RAJEEV_JAIN_KKUiwinNWf.jpeg?updatedAt=1679491138856",
},
{
    title:`TRAVEL SHOW - MICE TALK`,
    link:"https://www.facebook.com/MICEtalk/videos/1781050118640748/",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/TRAVEL_SHOW_-_MICE_TALK_ueqwQmYKpp.jpeg?updatedAt=1679491140200",
},
{
    title:`EEMAGINE2019 TALKS - WITH RAJEEV JAIN `,
    link:"https://www.youtube.com/watch?v=w_UgYmabRRI",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/EEMAGINE2019_TALKS_-_WITH_RAJEEV_JAIN_G6KrRGUrsZ.jpeg?updatedAt=1679491136547",
},
{
    title:`TRAVEL WORLD BUSINESS SUMMIT - RAJEEV JAIN SPEAKS ABOUT THE CHANGING FACE OF THE WEDDING BUSINESS 2020`,
    link:"https://www.facebook.com/watch/?v=598136257805164",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/TRAVEL_WORLD_BUSINESS_SUMMIT_-_RAJEEV_JAIN_SPEAKS_ABOUT_THE_CHANGING_FACE_OF_THE_WEDDING_BUSINESS_2020_cQpo1pPFYu.jpeg?updatedAt=1679491142384",
},
{
    title:`GDEC TALKSHOW WITH MR. RAJIV JAIN `,
    link:"https://www.youtube.com/watch?v=oJ_-D-lrr2k",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/GDEC_TALKSHOW_WITH_MR._RAJIV_JAIN_20J_PJPm3D.jpeg?updatedAt=1679491136715",
},
{
    title:`TRAVEL TALK 2020 - RAJEEVJAIN IMPARTING HIS THOUGHTS REGARDING THE CHANGES THAT THE INDIAN WE`,
    link:"https://www.facebook.com/watch/?v=843093109510913",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/TRAVEL_TALK_2020_-_RAJEEVJAIN_IMPARTING_HIS_THOUGHTS_REGARDING_THE_CHANGES_THAT_THE_INDIAN_WE_gI8b0T0pB_.jpeg?updatedAt=1679491140853",
},
{
    title:`EEMA 10 A DECADE OF TRANSFORMATION TALK BY RAJEEV JAIN`,
    link:"https://www.facebook.com/watch/?v=1682559928421193",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/EEMA_10_A_DECADE_OF_TRANSFORMATION_TALK_BY_RAJEEV_JAIN_rV50qWN2RE.jpeg?updatedAt=1679491136738",
},
{
    title:`RAJEEV JAIN IN CONVERSATION WITH BW APPLAUSE AND EVERYTHING EXPERIENTIAL`,
    link:"https://www.youtube.com/watch?v=tApcMwLs2cM",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/RAJEEV_JAIN_IN_CONVERSATION_WITH_BW_APPLAUSE_AND_EVERYTHING_EXPERIENTIAL_KZWlei2Xo.jpeg?updatedAt=1679491140122",
},
{
    title:`WEDDING TV EEMAGINE2049 WITH RAJEEV JAIN`,
    link:"https://www.youtube.com/watch?v=jLZJaSWQ0qY",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/WEDDING_TV_EEMAGINE2049_WITH_RAJEEV_JAIN_21wrrjp5F.jpeg?updatedAt=1679491142688",
},
{
    title:`EVENT MANAGER'S DAY- AN INSIGHT BY RAJIV JAIN `,
    link:"https://www.youtube.com/watch?v=jC-X5XgEriQ",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/EVENT_MANAGER_S_DAY-_AN_INSIGHT_BY_RAJIV_JAIN_IOww12lH-.jpeg?updatedAt=1679491136377",
},
{
    title:`TRAVEL WEDDING SHOW 2022 PART 1 MODERATED BY RAJEEV JAIN`,
    link:"https://www.facebook.com/watch/?v=997912253701660",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/TRAVEL_WEDDING_SHOW_2022_PART_1_MODERATED_BY_RAJEEV_JAIN_bFJzWy_9ar.jpeg?updatedAt=1679491140240",
},
{
    title:`TRAVEL WEDDING SHOW 2022 PART 2 BY RAJEEV JAIN`,
    link:"https://www.youtube.com/watch?v=fuFDhxbikHc",
    imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-400w/Rashi/media/TRAVEL_WEDDING_SHOW_2022_PART_2_BY_RAJEEV_JAIN__y2h1WFuub.jpeg?updatedAt=1679491141004",
},
]

export default MediaList