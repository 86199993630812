import { motion } from 'framer-motion'
import "./Frame.css"

function Frame({src,type,event=""}) {
  
  return (
      <motion.img
        animate={{filter:type?"blur(15px) brightness(45%)":"blur(0px) brightness(100%)"}}
        transition={{duration:1}}
        className="pic-frame"  src = {src} alt=""/>
  )
}

export default Frame