import React from 'react'
import { AnimatePresence, motion } from "framer-motion"

function Loader({title}) {
  return (
    <AnimatePresence>
        <motion.div className='overlay-container'
        initial={{opacity:1}}
        animate={{opacity:0}}
        transition={{duration:1}}>
            <h1>{title}</h1>
        </motion.div>
    </AnimatePresence>
  )
}

export default Loader