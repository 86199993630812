import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'
import "./Options.css"
import PathList from '../PathList';
import GalleryLoader from '../Loader/GalleryLoader';

function Options({passOptionFlag, aboutBtnRef, contactBtnRef, clientBtnRef, testimonialBtnRef, socialBtnRef, passOptionWasOpenFlag }) {
    const navigate = useNavigate();

    const opRef = useRef();

    const boxVariant ={
        hidden: {
            x: 0,
        },
        visible: {
            x:0,
            
            transition: {
                delay:0, 
                duration: 0,
                when: "afterChildren",
                staggerChildren: 0.13
            }
        }
    }

    const itemVariant ={
        hidden: {
            x: -150,
            opacity: 0
        },
        visible: {
            x: 0,
            opacity: 1,
            transition:{
                ease: [0.6,0.01,-0.05,0.95],
              }
        }
    }

    const  [navFlag, setNavFlag] = useState(false)

  /////////////////////////////////////////////////
  //////// Functions /////////////////////////////
  ////////////////////////////////////////////////
    const handleClick= (pathName)=>{
        passOptionFlag(false)
        passOptionWasOpenFlag(true)
        let path = "/"+pathName
    
        navigate(path)
    }

    //Pop up handling
    useEffect(()=>{
        let handler = (event) => {
            if(!opRef.current.contains(event.target)
            && !aboutBtnRef.current.contains(event.target)
            && !contactBtnRef.current.contains(event.target)
            && !clientBtnRef.current.contains(event.target)
            && !socialBtnRef.current.contains(event.target)
            && !testimonialBtnRef.current.contains(event.target) 
            ){
            passOptionFlag(false)
            passOptionWasOpenFlag(false)
            }
        };
        document.addEventListener("mousedown", handler);

        return()=>{
        document.removeEventListener("mousedown",handler);
        }
    })

  return (
    <>
    <motion.div className="popup-left-container" 
            animate="visible"
            variants={boxVariant}
            initial="hidden"
            ref={opRef}>
        
        {PathList.map((option)=>
            <motion.div className="popup-item" 
                variants={itemVariant}
                key={option.path}
                onClick={()=>handleClick(option.path)}
                >
                    {option.title}
            </motion.div>
            )
        }
    </motion.div>
    </>
  )
}

export default Options