import { useEffect, useState } from 'react'
import { useParams} from 'react-router-dom'
import { motion } from 'framer-motion'
import './Template.css'

import ImageList from '../../components/ImageList/ImageList'

function FinalTemplate({passNavbarFlag, passFooterFlag, 
  passPrevFooterFlag, passImageList, passCurrentImageIndex, 
  passImageSliderFlag}) {
  const params = useParams()

    const imageGalleryListFilter = ImageList?.filter((imgData) => 
          imgData.cover===false 
          && imgData.subCategory===params.subCategory)

    const imageGalleryList = imageGalleryListFilter?.map((filterData)=>{
      let tempString1 = filterData.src.substring(0,32)
      let tempString2 = filterData.src.substring(42, filterData.src.length)
      let temp = tempString1+"/tr:w-300"+tempString2+" 300w," + tempString1+"/tr:w-700"+tempString2+" 700w," + tempString1+"/tr:w-800"+tempString2+" 800w, "+tempString1+"/tr:w-1700"+tempString2+" 1000w"
      return {...filterData,
        srcSet: temp }})

    const coverListFilter = ImageList?.filter((coverData) => 
          coverData.subCategory===params.subCategory 
          && coverData.category===params.category
          && coverData.cover===true)
    
    const coverList = coverListFilter?.map((filterData)=>{
      let tempString1 = filterData.src.substring(0,32)
      let tempString2 = filterData.src.substring(42, filterData.src.length)
      let temp = tempString1+"/tr:w-300"+tempString2+" 300w," + tempString1+"/tr:w-400"+tempString2+" 400w," + tempString1+"/tr:w-900"+tempString2+" 800w, "+tempString1+"/tr:w-1920"+tempString2+" 1000w"
      return {...filterData,
        srcSet: temp }})

    const [blurBG, setBlurBG] = useState(false)
    const [check, setCheck] = useState(false)
    const [show, setShow] = useState(false)
    
  /////////////////////////////////////////////////
  //////// Functions /////////////////////////////
  ////////////////////////////////////////////////

  const handleImageClick=(index)=>{
    if(!blurBG){
      passCurrentImageIndex(index)
      passImageList(imageGalleryList)
      passImageSliderFlag(false)
      setTimeout(()=>{
        passImageSliderFlag(true)
      },100)
      
    }
  }

  // const handleScroll = () =>{

  // }
    
  /////////////////////////////////////////////////
  //////// Hooks /////////////////////////////////
  ////////////////////////////////////////////////
  
  //For page transition animation
  useEffect(()=>{
    if(passPrevFooterFlag){
      setCheck(true)

      setTimeout(()=>{
        setCheck(false)
      },100)
    }
  },[passPrevFooterFlag])

  //For smooth component render animation
  useEffect(()=>{
    if( (!passNavbarFlag && !passFooterFlag && !check )){
      setTimeout(()=>{
        setBlurBG(false)
      },400)
    }
    else if(check && !passFooterFlag){
      setBlurBG(false)
    }
    else {
      setBlurBG(true)
    }
  },[passNavbarFlag, passFooterFlag, check])

  //For showing rest of the page after cover image animation
  useEffect(()=>{
    setTimeout(()=>{
      setShow(true)
    },800)
  },[])

  return (
    <>
    <motion.div className="container template-container"
      animate={{filter:blurBG?"blur(15px) brightness(45%)":"blur(0px) brightness(100%)"}}
      transition={{duration:1}} >
      
      {coverList.length===0 ?

      // Error404 
        <div className='error404'>
          <h1 style={{color:"white", fontSize:"2rem"}}>404</h1>
          <h3 style={{color:"gray", marginBottom:"5vh"}}>Page not Found</h3>
        </div>
        :

      // Content

      //Section Header
      <div className="cover-header">
        <motion.div 
          className="cover-image" 
          layoutId={`${coverList.length===0? "":coverList[0].layoutID}`} 
          transition={{duration: 1.1, 
            ease:[0.8, 0.9, -0.05, 1] }}
            >
          <img 
            srcset={`${coverList[0].srcSet}`}
            sizes="(max-width: 768px) 800px,
              1000px"
            src={`${coverList.length===0? "":coverList[0].src}`} alt="" 
            className="cover-image"  />
        </motion.div>
            {/* <div className="scroll" ></div> */}
        
        {show &&

        //Title, Video and Writeup
        <> 
          {/* title */}
        <div className="final-template-title">
          <motion.h1
          animate={{opacity:1, y:0}}
          initial={{opacity:0, y:-600}}
          transition={{duration:1}} >
            {coverList.length>0 && coverList[0].title}
          </motion.h1>
        </div>

          {/* video */}
        {coverList[0].embedID!=="" && <div className="final-template-video">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${coverList[0].embedID}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>}
        
          {/* writeup */}
        <div className="final-template-writeup">
          <motion.div
            animate={{opacity:1,}}
            initial={{opacity:0, }}
            transition={{duration:1, delay:1}} >
              {coverList.length>0 && 
              coverList[0].writeup.map((para,index)=>
              <p key={index}
                style={{marginBottom:"1.5rem", fontFamily:"'Quattrocento', serif"}}
              >{para}</p>
              )}
            </motion.div>
        </div>
              
              
      
        </>
        }
      </div>}

      {/* gallery */}
      {coverList.length>0 && show &&
      <>
      <div className="cover-content">
          
        <div className="content-grid">
              {imageGalleryList.map((galleryItem, index)=>
              <motion.div
                className={`content-item ${blurBG?"disabled":""}`}
                initial={{opacity:0, y:0}}
                animate={{opacity:1, y:0}}
                transition={{duration:0, ease:"linear"}}
                key={galleryItem.src}>
              
                <img className={`content-item ${blurBG?"disabled":""}`}   
                  srcset={galleryItem.srcSet}
                  sizes="(max-width: 768px) 300px, 700px,
                  800px, 1000px"
                  src={galleryItem.src} alt=""
                  onClick={()=>handleImageClick(index)} 
                  loading={index>5?"lazy":"eager"}
                  />

              </motion.div>
            )}
        </div>
        
      </div>
      
      </>
      }
    <motion.div className="copyright-footer"
      initial={{opacity:0, y:0}}
      animate={{opacity:1, y:0}}
      transition={{duration:0.3,delay:1.1}}
    >
      © 2023 Rashi Entertainment Pvt. Ltd. All Rights Reserved 
    </motion.div>
    
    </motion.div>
    
    {/* <AnimatePresence >
    {imageSliderFlag && 
      <ImageSlider passImageSliderFlag={setImageSliderFlag} 
        imageList={imageGalleryList} 
        currentIndex={currentImageIndex} />}
    </AnimatePresence> */}
    </>
  )
}

export default FinalTemplate