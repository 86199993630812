import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import GalleryLoader from '../../components/Loader/GalleryLoader'
import "./Template.css"

import ImageList from '../../components/ImageList/ImageList'
import SubHeadingList from './SubHeadingList'

function Template({passNavbarFlag, passFooterFlag, 
    passPrevFooterFlag, passImageSliderFlag}) {
  const navigate = useNavigate()
  const params = useParams()

  const subHeading = SubHeadingList.filter(item=>item.category===params.category).map(item=>item.subH)[0]

  const imageCoverListFilter = ImageList.filter((imgData) => imgData.cover===true && imgData.category===params.category)

  // tempString1+"/tr:w-300"+tempString2+" 300w," +  tempString1+"/tr:w-600"+tempString2+" 600w," +
  const imageCoverList = imageCoverListFilter.map((filterData)=>{
  let tempString1 = filterData.src.substring(0,32)
      let tempString2 = filterData.src.substring(42, filterData.src.length)
      let temp = tempString1+"/tr:w-400"+tempString2+" 400w,"+   tempString1+"/tr:w-700"+tempString2+" 600w," + tempString1+"/tr:w-900"+tempString2+" 800w, "+tempString1+"/tr:w-1200"+tempString2+" 1000w"
      return {...filterData,
        srcSet: temp }})

  const [blurBG, setBlurBG] = useState(false)
  const [navFlag, setNavFlag] = useState(true)
  const [check, setCheck] = useState(false)

  const handleItemClick=(subC)=>{
    if(!blurBG){
      navigate(subC)
    }
  }
  
  /////////////////////////////////////////////////
  //////// Hooks /////////////////////////////////
  ////////////////////////////////////////////////

  useEffect(()=>{
    passImageSliderFlag(false)
    setNavFlag(true)
    
    setTimeout(()=>{
      setNavFlag(false)
    },1000)
  },[])

  //For page transition animation
  useEffect(()=>{
    if(passPrevFooterFlag){
      setCheck(true)

      setTimeout(()=>{
        setCheck(false)
      },100)
    }
  },[passPrevFooterFlag])

  //For smooth component render animation
  useEffect(()=>{
    if( (!passNavbarFlag && !passFooterFlag && !check)){
      setTimeout(()=>{
        setBlurBG(false)
      },1000)
    }
    else if(check && !passFooterFlag){
      setBlurBG(false)
    }
    else {
      setBlurBG(true)
    }
  },[passNavbarFlag, passFooterFlag, check, navFlag])

  return (
    <>
    {navFlag?
      <GalleryLoader /> 
    :
    
    <motion.div className="container template-container"
      animate={{filter:blurBG?"blur(15px) brightness(45%)":"blur(0px) brightness(100%)"}}
      transition={{duration:0.3}}>

        
        {imageCoverList.length===0?
         <div className='error404'>
          <h1 style={{color:"black", fontSize:"2rem"}}>404</h1>
          <h3 style={{color:"gray", marginBottom:"5vh"}}>Page not Found</h3>
          
        </div>
         :
         <>
         {/* <motion.div className='template-title-back'
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1}}
          style={{backgroundImage: `url(${SubHeadingList.filter(item=>item.category===params.category).map(item=>item.imageURL)})`}}
         ></motion.div> */}
        <div className="final-template-title template-title"
        > 
            <motion.h1
              initial={{opacity:0, y:-200}}
              animate={{opacity:1, y:0}}
              transition={{duration:0.8}}
              >
              {imageCoverList[0].category}
            </motion.h1> 
            
            
            {subHeading.map((item,index)=>
            <motion.div
            className="template-subscript"
            key={index}
            initial={{opacity:0}}
            animate={{opacity:1}}
            transition={{duration:1.2}}>
              {item}
            </motion.div>
            )}
        </div>

        <div className="container template-container"
          style={{paddingTop:"3rem"}}>
          <div className="cover-content">
            
          
            <div className="content-grid">            
              
              {imageCoverList.map((coverItem, index)=>
              
              <motion.div className='content-item'
               
                initial={{opacity:0, y:0}}
                animate={{opacity:1, y:0}}
                transition={{duration:0.1, ease:"linear"}}
                layoutId={coverItem.layoutID}
                key={coverItem.layoutID}>
                <img className='content-item-img' 
                  
                  src={coverItem.src} alt=""
                  srcset={coverItem.srcSet}
                  sizes="(max-width: 768px) 400px, 600px,
                  800px, 1000px"
                  onClick={()=>handleItemClick(coverItem.subCategory,coverItem.path)} 
                  loading={index>5?"lazy":"eager"}
                  />
                  <div className="content-item-text"
                  onClick={()=>handleItemClick(coverItem.subCategory)} >
                    {coverItem.title}
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>  
        </>}
        {/* copyright */}
        <div className="copyright-footer"> © 2023 Rashi Entertainment Pvt. Ltd. All Rights Reserved </div>
    </motion.div>
    }
    </>
  )
}

export default Template