import { useEffect, useState, useRef } from 'react'
import { motion } from "framer-motion"
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Frame from '../../components/Frame/Frame'
import GalleryLoader from '../../components/Loader/GalleryLoader'

function Social({passNavbarFlag, passFooterFlag, passPrevFooterFlag}) {
  const navigate = useNavigate()

  const ref=useRef(null);
  
  const imgSrc= "https://ik.imagekit.io/qljqw3tvn/tr:w-1920w/Rashi/Social.jpg"
  // const imgSrc = "./images/Social.jpg"

  const isSmallWindow = useMediaQuery({ query: '(max-width: 1280px)' })

  const [logoLocation, setLogoLocation] = useState({top:"50%", left:"60%"})

  const [blurBG, setBlurBG] = useState(false)
  const [check, setCheck] = useState(false)
  const [navFlag, setNavFlag] = useState(false)


  const handleNavigationAnimation =()=>{
    if(!blurBG){
      setNavFlag(true)
      setTimeout(()=>{
        navigate('gallery')
      },1000)
    }
  }

  const handleKeyDown = event => {
    if(event.key==="Enter"){
      handleNavigationAnimation()
    }
  };

  /////////////////////////////////////////////////
  //////// Hooks /////////////////////////////////
  ////////////////////////////////////////////////

  //For Rashi Logo Key Press Event
  useEffect(() => {
    ref.current.focus();
  }, []);

  //For page transition animation
  useEffect(()=>{
    if(passPrevFooterFlag){
      setCheck(true)

      setTimeout(()=>{
        setCheck(false)
      },100)
    }
  },[passPrevFooterFlag])

  //For smooth component render animation
  useEffect(()=>{
    if( (!passNavbarFlag && !passFooterFlag && !check)){
      setTimeout(()=>{
        setBlurBG(false)
      },400)
    }
    else if(check && !passFooterFlag){
      setBlurBG(false)
    }
    else {
      setBlurBG(true)
    }
  },[passNavbarFlag, passFooterFlag, check])

  //Logo location for mobile devices
  useEffect(()=>{
    if(isSmallWindow){
      setLogoLocation({top:"28%", left:"52%"})
    }
    else{
      setLogoLocation({top:"18%", left:"51%"})
    }
  },[isSmallWindow])

  return (
    <>
    <div className='container'>
        <Frame src= {imgSrc} type={blurBG} />

        <motion.div className={`r-dot ${blurBG?"r-dot-disable":""}`} ref={ref} tabIndex={-1} onKeyDown={handleKeyDown}
          animate={{filter:blurBG?"blur(15px) brightness(45%)":"blur(0px) brightness(100%)"}}
          transition={{duration:1}}
          style={logoLocation}>
            <img src="./images/R.png" alt="" className={`r ${blurBG?"r-disable":""}`}
            onClick={()=>handleNavigationAnimation()} />
        </motion.div>
    </div>
    {navFlag &&
      <GalleryLoader /> }
    </>
  )
}

export default Social