const AwardList = [{
    name:"WOW AWARDS MIDDLE EAST",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/WOW_AWARDS_MIDDLE_EAST_bHnp9Toya.jpg",
},
{
    name:"WOW AWARDS ASIA - BRONZE",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/WOW_AWARDS_ASIA_-_BRONZE_27dcghArC.jpg?updatedAt=1679383475319"
},
{
    name:"WOW AWARDS ASIA - 2019",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/WOW_AWARDS_ASIA2019_4VS0WnZRWE.jpg?updatedAt=1679383474362"
},
{
    name:"WOW AWARDS 2014 - Family fun car rally",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/WOW_AWARDS_2014_-_Family_fun_car_relly_ScvxBQYgtd.jpg?updatedAt=1679383474132"
},
{
    name:"WOW AWARDS 2013 12th Family Fun Car Rally",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/WOW_AWARDS_2013_12th_Family_Fun_Car_Rally_XFlv4k-LX.jpg?updatedAt=1679383473970"
},
{
    name:"WOW AWARDS 2014 13th Family Fun Car rally",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/WOW_AWARDS2014__13th_Family_Fun_Car_rally__0lgldvbIK.jpg?updatedAt=1679383473775"
},
{
    name:"WORLD TOURISM FORUM 2016",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/WORLD_TOURISM_FORUM_2016_R5O7ICjay.jpg?updatedAt=1679383472758"
},
{
    name:"SWADESH TOURISM",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/SWADESH_TOURISM_fgnhWWcwI.jpg?updatedAt=1679383472757"
},
{
    name:"MICE TRAVEL MART MTM 2023",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/MICE_TRAVEL_MART_MTM__2023_uLarARC4pE.jpg?updatedAt=1679383472434"
},
{
    name:"Great India Wdding GIWA 2019 - Gold",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/Great_India_Wdding__GIWA_2019_-_Gold_-rfHlrFM4.jpg?updatedAt=1679383472393"
},
{
    name:"NEPAL TOURISM BOARD",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/NEPAL_TOURISM_BOARD_vCiv8nSi-.jpg?updatedAt=1679383472219"
},
{
    name:"Great India Wdding GIWA 2019 - Silver",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/Great_India_Wdding__GIWA_2019_-_Silver_Aostd9cV3.jpg?updatedAt=1679383471547"
},
{
    name:"Great India Wdding GIWA 2019 - Gold",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/Great_India_Wdding__GIWA_2019_-__Gold_Qyo25-78md.jpg?updatedAt=1679383471483"
},
{
    name:"FORUM Federation of Rajsthan Event Managers",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/FORUM_Federation_of_Rajsthan_Event_Managers__-vWehZ7vR.jpg?updatedAt=1679383471228"
},
{
    name:"Family Fun Car Rally",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/family_fun_car_rally_p055OD9z2.jpg?updatedAt=1679383471130"
},

{
    name:"ENTERPRENEURSHIP 2009",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/ENTERPRENEURSHIP_2009_bL046T0UJ.jpg?updatedAt=1679383471025"
},

{
    name:"EMAX GLOBAL 2022 BEST SANGEET BRONZE ",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2022___BEST_SANGEET_BRONZE_exwzqXF333.jpeg?updatedAt=1679383469604"
},
{
    name:"EMAX GLOBAL 2019 BEST MICE Events based in India Silver",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2019_BEST_MICE_Events_based_in_India_Silver_kGoQK_LmKm.jpg?updatedAt=1679383469579"
},
{
    name:"EMAX GLOBAL 2017 BEST SOCIAL EVENTS GOLD",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2017___BEST_SOCIAL_EVENTS_GOLD_NbHQU_EhgM.jpg?updatedAt=1679383469272"
},
{
    name:"EMAX GLOBAL 2022 BEST WEDDING CEREMONY BRONZE ",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2022___BEST_WEDDING_CEREMONY_BRONZE_xA268GSAo4.jpg?updatedAt=1679383468840"
},

{
    name:"EMAX GLOBAL 2022 BEST MUSIC EVENT BRONZE",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2022___BEST_MUSIC_EVENT_BRONZE_pURtgDDem.jpg?updatedAt=1679383468687"
},

{
    name:"EMAX GLOBAL 2022 BEST DESTINATION WEDDING GOLD",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2022___BEST_DESTINATION_WEDDING_GOLD_E3SGUj7JM.jpg?updatedAt=1679383468545"
},
{
    name:"EMAX GLOBAL 2016 BEST SOCIAL EVENTS BRONZE",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2016___BEST_SOCIAL_EVENTS_BRONZE_cB7Z7U9xj.jpg?updatedAt=1679383468287"
},
{
    name:"EMAX GLOBAL 2017 BEST WEDDING SILVER",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2017___BEST_WEDDING_SILVER_lh8b6tFBXe.jpg?updatedAt=1679383468157"
},
{
    name:"EMAX GLOBAL 2019 BEST DESTINATION WEDDING BRONZE",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2019___BEST_DESTINATION_WEDDING_BRONZE_ovIz0mxqaE.jpg?updatedAt=1679383468140"
},

{
    name:"EMAX GLOBAL 2015 SILVER JUBLIE CELEBRATION",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2015___SILVER_JUBLIE_CELEBRATION__Jo_EVoh2-.jpg?updatedAt=1679383468051"
},

{
    name:"EEMAX 2014",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EEMAX_2014_yfMjY9a68J.jpg?updatedAt=1679383464585"
},
{
    name:"EEMAX 2014 Family Fun Car rally ",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EEMAX_2014_Family_Fun_Car_rally_E2nmF5hVeY.jpg?updatedAt=1679383464564"
},
{
    name:"2020 IIFA WEEKEND MADHYA PRADESH ",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/2020_IIFA_WEEKEND__MADHYA_PRADESH_w2crZ_pvm.jpg?updatedAt=1679383464530"
},
{
    name:"AECL 2019",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/AECL_2019_jDcUWh5hS.jpg?updatedAt=1679383464526"
},

{
    name:"EEMA EAST CONCLAVE 2018",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EEMA_EAST_CONCLAVE_2018_wU7Lz0exM.jpg?updatedAt=1679383464509"
},

{
    name:"ABHAR SHIKSHAKO KA ",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/ABHAR_SHIKSHAKO_KA_9lo5KN1F7.jpg?updatedAt=1679383464491"
},
{
    name:"Best social events ",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/Best_social_events_VGtj5CF5g1.jpg?updatedAt=1679383464501"
},
{
    name:"EMAX GLOBAL 2015 DECOR DESIGN FOR A WEDDING SILVER",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2015_DECOR_DESIGN_FOR_A_WEDDING_SILVER_5GFq3sYfBN.jpg?updatedAt=1679383464485"
},
{
    name:"EMAX GLOBAL 2015 BEST SOCIAL EVENTS",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2015___BEST_SOCIAL_EVENTS__dtPwy9f-M.jpg?updatedAt=1679383464440"
},

{
    name:"EMAX GLOBAL 2015 BEST DEALER ACTIVATION GOLD",
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/awards/EMAX_GLOBAL_2015___BEST_DEALER_ACTIVATION_GOLD_oui7fBCMVe.jpg?updatedAt=1679383464394"
},]

export default AwardList