import { useEffect, useState } from 'react'
import Frame from '../../components/Frame/Frame'
import Loader from '../../components/Loader/Loader'

function Landing({passNavbarFlag, passFooterFlag, passPrevFooterFlag}) {
  // const imgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-1920/Rashi/New_Home.jpg"
  // const imgSrc = "https://ik.imagekit.io/qljqw3tvn/Rashi/weddings/cover_DA_zoHD28GwG.jpeg"
  const imgSrc = "https://ik.imagekit.io/qljqw3tvn/Rashi/home_test_2Cf_odRlf.jpeg?updatedAt=1710741571041"

  const [loading, setLoading] = useState(true)
  const [blurBG, setBlurBG] = useState(false)
  const [check, setCheck] = useState(false)

  /////////////////////////////////////////////////
  //////// Hooks /////////////////////////////////
  ////////////////////////////////////////////////

  //For page loading animation
  useEffect(()=>{
      setTimeout(()=>{
        setLoading(false)
      },1000)
  },[])

  //For page transition animation
  useEffect(()=>{
    if(passPrevFooterFlag){
      setCheck(true)

      setTimeout(()=>{
        setCheck(false)
      },100)
    }
  },[passPrevFooterFlag])

  //For smooth component render animation
  useEffect(()=>{
    if( (!passNavbarFlag && !passFooterFlag && !check)){
      setBlurBG(false)
    }
    else if(check && !passFooterFlag){
      setBlurBG(false)
    }
    else {
      setBlurBG(true)
    }
  },[passNavbarFlag, passFooterFlag, check])

  return (
    <div className='container'>
      {loading && <Loader title={"Rashi Entertainment"}/> }
      <Frame src={imgSrc} type={blurBG}/>
    </div>
  )
}

export default Landing