const TestimonialList = [
    
    // {
    //     imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/B.P_Agarwal__Priyagold__wJ3cftT4Jc.jpg",
    //     heading:"B.P. Agarwal",
    //     designation:" Priyagold (Chairman)",
    //     paragraph:[`Priyagold has been associated with Rashi Entertainment for last many years through various launches of our products. The way the hard working and competent team of Rashi has handled our projects was unparallel and indeed commendable`]
    // },
    // {
    //     imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/Yogesh_Jain_-_Mahak_Group_of_Industries_Nl2O4LmPq4.jpg",
    //     heading:"Yogesh Jain",
    //     designation:"Mahak Group of Industries",
    //     paragraph:[`The event ran seamlessly and efficiently which made our venue more beautiful. You have excelled beyond our wildest expectations. Your whole team was so professional and helpful and it was a real pleasure having you organize our wedding.`,]
    // },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/Rashi/Testimonials/snShrivastava_j9rkr0oi4.jpg?updatedAt=1709543787656",
        heading:"SN Shrivastava",
        // designation:"NITCO (Managing Director)",
        paragraph:[`Delhi ke famous stars jo film jagat ke aur singing jagat ke hain, woh bhi saamne aakar virtual concert organise kiye hain jisse ye pura mahoul achha ho raha hai. Main Rashi Entertainment, unko bhi dhanyawad deta hu kyunki ye iss virtual concert ke peeche hain. Unko bahut bahut dhanyawad.`,]
    },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/Rashi/Testimonials/mdAkli_aH2DEE7_7q.jpg?updatedAt=1709543787595",
        heading:"Muhammad Akil",
        designation:"IPS (Commissioner of Police, Gurugram)",
        paragraph:[`Rashi Entertainment and Rajeev Jain, thanks a lot for assisting Gurugram police for corona prevention. Your initiative of involving film personalities and artists in this task, in this cause is really appreciable. It will surely go a long way in preventing the corona spread. Thank you so much.`,]
    },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/Rashi/Testimonials/rajanishKumarSingh_D3sbJCLbg.jpg?updatedAt=1709543787655",
        heading:"Rajanish Kumar Singh",
        designation:"Director, SCERT",
        paragraph:[`SCERT ke 35 saal ke itihaas me na pehle kabhi aisa hua hai na aisa kabhi hoga, itna acha program tha, itne kam samay me jitne bhi log ye saare arrangements me lage hue the, chahe humare event management team ho, chahe Rashi Entertainment Pvt. Ltd. ke log ho, chahe SCERT ke staffs ho, jitne bhi log lage hue the, inko jitna thank you bolu, mere paas shabd kam hai.`,]
    },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/Rashi/Testimonials/gauravThakker_dPFWZK5plI.jpg?updatedAt=1709543787600",
        heading:"Gaurav Thakker",
        designation:"Chairman Youthcon’19 Credai",
        paragraph:[`By God’s Grace and Rashi Entertainment ki puri team ki mehnat, everything was implemented very well. In time, sab kuch ho gaya.`,]
    },
    
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/Vivek_Talwar_-_NITCO__Managing_Director__i-5K_ocEz7.jpg",
        heading:"Vivek Talwar",
        designation:"NITCO (Managing Director)",
        paragraph:[`The mega launch of our product Naturoc - GresLapato series in 10 cities across the whole of India within a short span of 22 days was indeed a huge success. The passion and commitment with which Rashi shared our vision and handled the concept, product display, entertainment & other logistics was unparalleled!`,]
    },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/Adesh_Kumar_Gupta__Chief_Executive_Officer_Liberty_Shoes__bprEeNWWZf.jpg",
        heading:"Adesh Gupta",
        designation:"Liberty (CEO)",
        paragraph:[`Liberty is associated with Rashi for the past several years for official events as well as family functions. Rashi has organised various functions in a magnificent manner. All the events were not only flawless and to our expectations and planning but also heart throbbing.`,]
    },
    // {
    //     imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/SK_Jain_Mahak_Pan_masala__HM4x4w7Uc.jpg",
    //     heading:"S.K. Jain",
    //     designation:"Mahak Pan Masala (MD)",
    //     paragraph:[`You have successfully managed so many magnificent shows/events for Mahak Group; launching our products arranging family events like marriage functions, Holi, Birthday, Namkaran, etc. You give personal touch to the minute details to make the event graceful, enjoyable and memorable.`,]
    // },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/S.K_Agarwal_Kamdhenu_Paints__b9o7uVNof8.jpg",
        heading:"SK Agarwal",
        designation:" Kamdhenu Paints & Kamdhenu Steel (Chairman & MD)",
        paragraph:[`On the grand launch of Kamdhenu Paints (Colour Dreamz), all our dealers and distributors had a great experience. It was fantastic journey with Rashi Entertainment till date. Hope we will do tremendous business together.`,]
    },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/Pradeep_Jain_Prasvnath__49uPrtcXMq.jpg",
        heading:"Pradeep Jain",
        designation:"Parsvnath Developers Ltd. (Chairman)",
        paragraph:[`Your company's splendid growth is a testimony of your and your dedicated team's hard work for keeping your customers happy. I wish the organisation and its team further success and steady progress and prosperity.`,]
    },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/S.M_Azmat_Sales__mktg._Jaypee_Hotels__mPq2YEA4wG.jpg",
        heading:"S.M. Azmat",
        designation:" Sales & mktg. - Jaypee Hotels (Joint President)",
        paragraph:[`It gives us great pleasure to congratulate you your team to have completed the successful journey and providing entertainment to fulfil the needs of marketing and promotions for your clients.`,]
    },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/Gurnam_Arora__Kohinoor_Foods__s8-2HVu5n.jpg",
        heading:"Gurnam Arora",
        designation:"Kohinoor Foods Ltd. (MD)",
        paragraph:[`Rashi Entertainment has been associated with us since their beginning of business and has done our various projects like Kohinoor Club, few dealers meet, our family weddings. We have been their co-sponsors for their 9th Family Fun Car Rally since its inception in the year 2001 and have derived good mileage for our brand every year.`,]
    },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/Rashi/Testimonials/khoosbooAndAaryan_L3dDVexcqE.jpg?updatedAt=1709543787659",
        heading:"Khushboo & Aryan",
        designation:"",
        paragraph:[`Dear Team Rashi,`,
                    `We hope this message finds you in great spirits. As we step into the joyous phase of our married life, we cannot help but reflect on the incredible journey that led us here, and we wanted to take a moment to express our deepest gratitude to you.`,    
                    `From the very first meeting, you exuded professionalism, passion, and a genuine understanding of our vision for the perfect wedding day. Your dedication and meticulous planning made the entire process a seamless and enjoyable experience for us.`,
                    `Your warm and reassuring presence throughout the planning process made us feel at ease, knowing that we could trust you completely with one of the most important days of our lives. Thank you for making our dream wedding a reality.`,
                    `Wishing you continued success and happiness in all your future endeavors.`,
                    `With sincere appreciation and warmest regards.`
                ]
    },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/Vaibhav.jpg",
        heading:"VAIBHAV JAIN",
        designation:"Groom",
        paragraph:[`Rashi Entertainment has helped us from our Roka to the Grand Finale Le; the Reception. So, they helped us with finalizing very small minute details whether it is hotel, gift, room hampers, little customization, voice notes, regarding hospitality, sending notes & locations to the guests. Moreover the Artists, Entertainment, whether it's Bar, Liquor or party. So I think this is a one Stop-Shop-Solution for everything, whatever you need, they are the best.`,
            `The experience was extremely tremendous & amazing. The entire team worked with their heart & soul. They were giving their 24 Hours in a day for us & they were amazing. Moreover, I wanted say there were many small Points which people generally miss, very small points which they covered up & this is a very big thing. Rashi Entertainment helped us in organizing everything.`]
    },
    {
        imgSrc:"https://ik.imagekit.io/qljqw3tvn/tr:w-800w/Rashi/Testimonials/Ishita_-XmTR9Eeg.jpg",
        heading:"ISHITA VARSHNEY JAIN ",
        designation:"Bride",
        paragraph:[`Rashi Entertainment-I think the team has been a very good at this work of handling such a big wedding in such a short span of time especially. Second of all, the team in itself was very coordinated & very much sorted due to which we could really ready on them. And the energy they could bring out of them was tremendous which really boosted us up & really helped us calm ourselves & sit back & relax for this whole wedding thing. The outcome has been superb like their response & everything has been wonderful. I think Rashi Entertainment has done a fabulous job. Thank you so much!`]
    },
    
    // {
    //     imgSrc:"",
    //     heading:"Adash Gupta - Liberty (Executive Director)",
    //     paragraph:[`I am sure with 100% commitment, hard work and for honesty providing excellent services, the founder of Rashi Entertainment Pvt. Ltd., Mr. Rajeev Jain will be able to take his company to new height in the coming year.`,]
    // },

]

export default TestimonialList