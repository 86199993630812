import { useState, useEffect, useRef }from 'react'
import { HiMenu, HiMenuAlt2 } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram} from "react-icons/fa"

import "./Navbar.css"
import "../../pages/About/About.css"
import AboutMobile from '../../pages/About/AboutMobile'
import OptionsMobile from '../OptionsPopup/OptionsMobile'
import ClientMobile from '../../pages/Clients/ClientMobile'
import ContactMobile from '../../pages/Contact/ContactMobile'
import ImageSlider from '../ImageSlider/ImageSlider'
import Testimonial from '../../pages/Testimonial/Testimonial'
import PathList from '../PathList'

function NavbarMobile({passNavbarFlag, passModalFlag, 
  passPrevModalFlag, passImageSliderFlag,
  passImageList, passCurrentImageIndex}) {
    const navigate = useNavigate()

    const mobilePopupRef = useRef();
    const mobileHeaderRef = useRef();

    const aboutBtnRef = useRef();
    const aboutDivRef = useRef();

    const testimonialBtnRef = useRef();
    const testimonialDivRef = useRef();
    
    const contactDivRef = useRef();
    const contactBtnRef = useRef();
    
    const clientDivRef = useRef();
    const clientBtnRef = useRef();
    
    const footerRef = useRef();
    const socialBtnRef = useRef();

    const [modalOpen, setModalOpen] = useState(false)

    const [mobileHeaderFlag, setMobileHeaderFlag] = useState(false);

    const [aboutFlag, setAboutFlag]=  useState(false)
    const [testimonialFlag, setTestimonialFlag]=  useState(false)
    const [contactFlag, setContactFlag]=  useState(false)
    const [clientFlag, setClientFlag]=  useState(false)
    const [prevOptionFlag, setPrevOptionFlag] = useState(false)
    const [imgSliderFlag, setImgSliderFlag] = useState(passImageSliderFlag)

    const [darkBG, setDarkBG] = useState(true)

  /////////////////////////////////////////////////
  //////// Functions /////////////////////////////
  ////////////////////////////////////////////////

    const handleFooterClick=()=>{
        if( !(mobileHeaderFlag)){
        setModalOpen(true)
        setPrevOptionFlag(false)
        }
    }
    
    const handleHeaderClick=()=>{
        if( !(aboutFlag || contactFlag || clientFlag || testimonialFlag || modalOpen || imgSliderFlag)){
        // setModalOpen(true)
        // setPrevOptionFlag(false)
        setMobileHeaderFlag(!mobileHeaderFlag)
        }
        else if(modalOpen){
            setMobileHeaderFlag(false)
        }
    }

    const handleAboutClick=()=>{
        if(!(modalOpen || imgSliderFlag)){
            setAboutFlag(!aboutFlag)
            setClientFlag(false)
            setContactFlag(false)
            setTestimonialFlag(false)
        }
        // else if()
    }

    const handleTestimonialClick=()=>{
      if(!( modalOpen || imgSliderFlag)){
        setTestimonialFlag(!testimonialFlag)
        setAboutFlag(false)
        setClientFlag(false)
        setContactFlag(false)
      }
    }

    const handleClientClick =()=>{
      if(!(  modalOpen || imgSliderFlag)){
        setClientFlag(!clientFlag)
        setAboutFlag(false)
        setContactFlag(false)
        setTestimonialFlag(false)
      }
    }

    const handleContactClick=()=>{
      if(!( modalOpen || imgSliderFlag) ){
        setContactFlag(!contactFlag)
        setAboutFlag(false)
        setClientFlag(false)
        setTestimonialFlag(false)
      }
    }

  /////////////////////////////////////////////////
  //////// Hooks /////////////////////////////////
  ////////////////////////////////////////////////

  //Dark Background Logic
  useEffect(()=>{ 
    const paths = PathList.map((item)=> "/"+item.path)
    // console.log(`paths:${JSON.stringify(paths, null,4)}`)
    // console.log(window.location.pathname)
    if( (paths.includes(window.location.pathname) || window.location.pathname === "/" )){
      setDarkBG(true)
      // console.log(`darkBg:${false}`)
    }else{
      setDarkBG(false)
      // console.log(`darkBg:${false}`)
    }
  },[window.location.pathname])

  useEffect(()=>{
    passNavbarFlag(mobileHeaderFlag || imgSliderFlag)
    // console.log(mobileHeaderFlag)
  },[mobileHeaderFlag, imgSliderFlag, passNavbarFlag])


  useEffect(()=>{
    passModalFlag(modalOpen)
  },[modalOpen,passModalFlag])

  useEffect(()=>{
    passPrevModalFlag(prevOptionFlag)
  },[prevOptionFlag, passPrevModalFlag])

  useEffect(()=>{
    setImgSliderFlag(passImageSliderFlag)
  },[passImageSliderFlag])

  return (
    <>
    <div className={`navbar-container ${(darkBG||mobileHeaderFlag||modalOpen)?"navbar-dark-container":""}`}>
      <div className="fake-backdrop"></div>
    </div>

    <div className={`navbar-container ${(darkBG||mobileHeaderFlag||modalOpen)?"navbar-dark-container":""}`}>
        
        <div className="nav-item" ref={mobileHeaderRef}>
            {mobileHeaderFlag?
            <HiMenuAlt2 className='icon-mobile'  
                onClick={()=>handleHeaderClick()}
                 />:
            <HiMenu className={`${modalOpen || imgSliderFlag? "icon-disabled":"icon-mobile"} `} 
                onClick={()=>handleHeaderClick()}
                 />}
        </div>

        <div className="nav-item">
            <div className='btn stable'
              onClick={()=> navigate('/')}>
                Rashi Entertainment
            </div>
        </div>

        {mobileHeaderFlag &&
        <div className="popup-mobile-container" ref={mobilePopupRef}>
            
            <div className={`btn ${ (modalOpen || imgSliderFlag) ?"disabled":""}`}
                onClick={(e)=>handleAboutClick()}
                ref={aboutBtnRef} >
                About
            </div>

            <div className={`btn ${ (modalOpen || imgSliderFlag) ?"disabled":""}`}
              onClick={()=>handleTestimonialClick()}
              ref={testimonialBtnRef} >
                Testimonials
            </div>

            <div className={`btn ${( modalOpen || imgSliderFlag) ?"disabled":""}`}
              onClick={()=>handleClientClick()}

              ref={clientBtnRef}>
                Clients
            </div>

            <div className={`btn ${( modalOpen || imgSliderFlag) ?"disabled":""}`} 
              onClick={()=> handleContactClick()} 
              ref={contactBtnRef}
            >
              Contact
            </div>

        </div>
        }

    </div>

    <AnimatePresence>
        {aboutFlag && 
          <motion.div  className="content-box" ref={aboutDivRef}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1}}
          exit={{ opacity:0}}
          >
            <AboutMobile passAboutFlag={setAboutFlag}
              btnRef={aboutBtnRef} 
              contactBtnRef={contactBtnRef}
              clientBtnRef={clientBtnRef}
              testimonialBtnRef={testimonialBtnRef}
              divRef={aboutDivRef}
              footerRef={footerRef}
              socialBtnRef={socialBtnRef}
            /> 
           </motion.div> }
          
    </AnimatePresence >

    <AnimatePresence>
        {testimonialFlag && 
          <motion.div  className="testimonial-container" ref={testimonialDivRef}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1}}
          exit={{ opacity:0}}
          >
            <Testimonial passTestimonialFlag={setTestimonialFlag}
              btnRef={testimonialBtnRef} 
              contactBtnRef={contactBtnRef}
              clientBtnRef={clientBtnRef}
              aboutBtnRef={aboutBtnRef}
              socialBtnRef={socialBtnRef}
              footerRef={footerRef}
            /> 
           </motion.div> }
          
    </AnimatePresence >

    <AnimatePresence>
        {contactFlag && 
        <motion.div  className="contact-container" ref={contactDivRef}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1}}
          exit={{ opacity:0}}
        >
          <ContactMobile passContactFlag={setContactFlag} 
            btnRef={contactBtnRef}
            aboutBtnRef={aboutBtnRef}
            clientBtnRef={clientBtnRef}
            testimonialBtnRef={testimonialBtnRef}
            footerRef={footerRef}
            socialBtnRef={socialBtnRef}/> 
        </motion.div>  }
    </AnimatePresence>

    <AnimatePresence>
        {clientFlag && 
        <motion.div  className="client-container" ref={clientDivRef}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:1}}
          exit={{ opacity:0}}
        >
          <ClientMobile passClientFlag={setClientFlag} 
            btnRef={clientBtnRef}
            aboutBtnRef={aboutBtnRef}
            contactBtnRef={contactBtnRef}
            testimonialBtnRef={testimonialBtnRef}
            footerRef={footerRef}
            socialBtnRef={socialBtnRef}/> 
        </motion.div>  }
    </AnimatePresence>

    <AnimatePresence >
    {imgSliderFlag && 
      <ImageSlider 
        passImageSliderFlag={setImgSliderFlag} 
        imageList={passImageList} 
        currentIndex={passCurrentImageIndex} />}
    </AnimatePresence>
    
    {/* Footer */}
    <div className="transparent-container">
      <div className={`footer-container ${darkBG?"footer-dark":""}`}>
        <div className="item"
            ref={footerRef}
            >
              
            {!modalOpen &&
                <motion.div className={`btn-footer ${mobileHeaderFlag?"disabled":""}`}
                initial={{opacity:0}}
                animate={{opacity:1}}
                exit={{opacity:0}}
                onClick={()=>handleFooterClick()}
                ref={footerRef} >
                  Explore
                </motion.div> 
            }

            <AnimatePresence>
            {modalOpen &&
                <motion.div className='popup-container'
                animate={{y:0,opacity:1}}
                exit={{y:50, opacity:0}}
                transition={{duration:0.6}}>
                  
                  <OptionsMobile passOptionFlag={setModalOpen} 
                    headerRef = {mobileHeaderRef}
                    socialBtnRef={socialBtnRef}
                    passOptionWasOpenFlag={setPrevOptionFlag} 
                    />
                </motion.div>
                }
            </AnimatePresence>
        </div>
        <div className="item">
                <motion.div className={`btn-footer ${(aboutFlag 
                  || contactFlag || clientFlag || testimonialFlag )?"disabled":""}`}
                  initial={{opacity:0}}
                  animate={{opacity:1}}
                  exit={{opacity:0}}
                  ref={socialBtnRef} 
                  style={{}}
                  // onClick={()=>console.log(`Social`)}
                  >

                    {/* <a href="https://twitter.com/RashiEnt"  target="_blank" rel="noreferrer">
                      <FaTwitter style={{margin:"0 1rem", color:"white"}}/>
                    </a> */}

                    <a href="https://www.instagram.com/rashi.entertainment/"  target="_blank" rel="noreferrer">
                      <FaInstagram style={{margin:"0 1rem", color:"white"}}/>
                    </a>

                    <a href="https://www.youtube.com/channel/UC0xwYBjgvNOhP-DuNiW1pxw"  target="_blank" rel="noreferrer">
                      <FaYoutube style={{margin:"0 1rem", color:"white"}}/>
                    </a>

                    <a href="https://www.facebook.com/rashientertainment/"  target="_blank" rel="noreferrer">
                      <FaFacebookF style={{margin:"0 1rem", color:"white",}}/>
                    </a>
                </motion.div>
        </div>
      </div>
    </div>
    </>
  )
}

export default NavbarMobile