import {motion} from "framer-motion"

function GalleryLoader() {
  const bgColour = "#000000"
  // const bgColour = "#404048"

  return (
    <>
    <motion.div className="overlay-container"
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:0.4}}
        style={{backgroundColor:"white"}} >
        <h1 style={{color:bgColour, position:"absolute", zIndex:"1"}}>Rashi Entertainment</h1>
    </motion.div>
        
    <motion.div className="overlay-container"
        initial={{x:"-100vw"}}
        animate={{x:0}}
        transition={{delay:0.4,duration:0.6}} 
        style={{backgroundColor:bgColour}} >
        
    </motion.div>

    <motion.div className="fake-backdrop" 
        initial={{y:"-10vh"}}
        animate={{y:0}}
        transition={{delay:0.2,duration:0.7}}></motion.div>

    <motion.div className="fake-backdrop" 
        initial={{y:"-10vh"}}
        animate={{y:0}}
        transition={{delay:0.2,duration:0.7}}></motion.div>
    </>
  )
}

export default GalleryLoader