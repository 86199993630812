import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

function PageNotFound({passNavbarFlag, passFooterFlag,
    passPrevFooterFlag}) {
        const [blurBG, setBlurBG] = useState(false)
        const [check, setCheck] = useState(false)

        /////////////////////////////////////////////////
        //////// Hooks /////////////////////////////////
        ////////////////////////////////////////////////

        //For page transition animation
        useEffect(()=>{
        if(passPrevFooterFlag){
            setCheck(true)
            setTimeout(()=>{
            setCheck(false)
            },100)
        }
        },[passPrevFooterFlag])

        //For smooth component render animation
        useEffect(()=>{
        if( (!passNavbarFlag && !passFooterFlag && !check)){
            setTimeout(()=>{
            setBlurBG(false)
            },400)
        }
        else if(check && !passFooterFlag){
            setBlurBG(false)
        }
        else {
            setBlurBG(true)
        }
        },[passNavbarFlag, passFooterFlag, check])

  return (
    <motion.div className='container' style={{background:"linear-gradient(90deg, rgba(139,22,82,1) 0%, rgba(196,43,4,1) 100%);"}} 
        animate={{filter:blurBG?"blur(15px) brightness(45%)":"blur(0px) brightness(100%)"}}
        transition={{duration:1}}  >

        <h1 style={{color:"white", fontSize:"2rem"}}>404</h1>
        <h3 style={{color:"rgb(195,182,189)", marginBottom:"5vh"}}>Page not Found</h3>

    </motion.div>
  )
}

export default PageNotFound