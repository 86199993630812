import {useEffect, useRef} from 'react'
import Form from '../../components/Form/Form'
import "./Contact.css"

function ContactMobile({passContactFlag, btnRef, aboutBtnRef, clientBtnRef, testimonialBtnRef, footerRef, socialBtnRef}) {
  const contactRef = useRef();

  //Pop up handling
  useEffect(()=>{
      let handler = (event) => {
          if(!contactRef.current.contains(event.target) 
          && !btnRef.current.contains(event.target)
          && !aboutBtnRef.current.contains(event.target)
          && !footerRef.current.contains(event.target)
          && !clientBtnRef.current.contains(event.target)
          && !testimonialBtnRef.current.contains(event.target)
          && !socialBtnRef.current.contains(event.target)
          ){
          passContactFlag(false)
          }
      };
      document.addEventListener("mousedown", handler);

      return()=>{
      document.removeEventListener("mousedown",handler);
      }
  })

  return (     
      <div className="contact-content" ref={contactRef}>
    
        <p>Thank you for your interest in Rashi Entertainment as your Event Planning Company.</p> 
        <p>Please use the contact form for any inquires. We can’t wait to get to know you!</p>
        
        <div className="info-grid">
          
          <div className='info-item'>
            <h3>PHONE</h3>

            <a href="tel:+919310288888">
              <p>+91 93102 88 888</p>
            </a>

            <a href="tel:011-40546007">
              <p>011-4054 6007</p>
            </a>

          </div>

          <div className='info-item'>
            <h3>E-MAIL</h3>
            <a href='mailto:info@rashientertainment.com'>
              <p>info@rashientertainment.com</p>
            </a>
          </div>
          
          <div className='info-item'>
            <h3>ADDRESS</h3>
            <p>B-9, Greater Kailash Enclave II, New Delhi – 48</p>
          </div>
          
        </div>

        <Form />
      </div>
  )
}

export default ContactMobile