import { useEffect, useState, useRef } from 'react'
import { motion } from "framer-motion"
import { FiChevronLeft, FiChevronRight, FiX } from "react-icons/fi";
import "./ImageSlider.css"

function ImageSlider({passImageSliderFlag, imageList, currentIndex}) {
    const [current, setCurrent] = useState((typeof currentIndex)==="undefined"? 0:currentIndex)

    const ref=useRef(null);

    //Functions 
    const prevSlide=()=>{
        setCurrent(current === 0 ? imageList.length-1: current-1)
    }

    const nextSlide=()=>{
        setCurrent(current === imageList.length -1 ? 0: current+1)
    }

    const handleKeyDown = event => {
        if(event.key==="ArrowRight"){
            nextSlide()
        }else if(event.key==="ArrowLeft"){
            prevSlide()
        }else if(event.key==="Escape"){
            passImageSliderFlag(false)
        }
      };

    useEffect(() => {
        ref.current.focus();
      }, []);

  return (
    <div className="imageSlider-container" ref={ref} tabIndex={-1} onKeyDown={handleKeyDown}>
        <FiChevronLeft className='slider-icon left-icon'
            onClick={()=>prevSlide()}
        />
        <FiChevronRight className='slider-icon right-icon'
            onClick={()=>nextSlide()}
        />

        <FiX className='slider-icon close'
            onClick={()=>passImageSliderFlag(false)}
        />

        {imageList.map((imageData, index)=>{
            return(
            <motion.img className={`slider ${index===current? "":"hidden"}`}
                initial={{opacity:0}}
                animate={{opacity:1}}
                exit={{opacity:0}}
                transition={{duration:0.6, ease:"linear"}}    
                src={imageData.src} alt=""
                key={index} />
            )
        })}
    </div>
  )
}

export default ImageSlider