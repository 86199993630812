const ClientList = [{ 
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/RCPL_OtAxzJ-Xy2.jpg" 
    ,type:"1"    },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/ford-logo-2017_AxAN-l1Ja.png" 
    ,type:"2"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/EO_1lhT_6rP_.jpg" 
    ,type:"3"        },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Airtel-Logo_hrtzJYux-Z.png" 
    ,type:"4"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/tata-teleservices-logo-organization-tata-group-brand-png_Y4aKpaE0M.png" 
    ,type:"5"        },

{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Indian-Oil-Logo_vnX_s0yY7.png" 
    ,type:"2"    },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Sony_ZFD6DKVyx.jpg" 
    ,type:"3"      },  
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/honda-hd-png-honda-logo-png_XU0-WHIkh.png" 
    ,type:"4"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/hyundai_97gCBym6Q4.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/TVS_Motor_Company-Logo.wine__0ERDjNlWhU.png" 
    ,type:"5"     },

{
    src:"https://i.ibb.co/jw0G86g/yamaha-logo-motorcycle-brands-png-3.png" 
    ,type:"3"     },
{
    src:"https://i.ibb.co/kGxLYZb/Woosh-Logo-with-White-Patch-2-768x384-1.png" 
    ,type:"4"     },
{
    src:"https://i.ibb.co/mcCrv3W/toyota-logos-brands-logotypes-0.png" 
    ,type:"5"     },
{
    src:"https://i.ibb.co/tCM79Dd/Tivoli-Software-Logo-wine.png" 
    ,type:"5"        },
{
    src:"https://i.ibb.co/R0T95JL/Tata-Group-logo.png" 
    ,type:"5"     },

{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/ramada_o9Am9Q19wg.png" 
    ,type:"4"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/beetel_7j9qAY5ZtE.png" 
    ,type:"5"        },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/kamdehnu.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/liberty_eKbNsSSj2.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/parsvnath_O7TyNqFqp.png" 
    ,type:"5"     },

{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/PPJ_7i7m2zmai.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/nai_duniya_xv8vSqVYud.png" 
    ,type:"5"        },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/fern_jaipur_kelug9ksC.png" 
    ,type:"5"        },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/archies_DxJN2PsvKq.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/sahara_MBKSiXtkS.png" 
    ,type:"5"        },

{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/roayla_orchid_jpgiOAO4yu.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/realty__wpdEIPLxlJ.png" 
    ,type:"5"        },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/chevrolet-impala-car-general-motors-logo-chevrolet-logo-car_tD-EbpYypV.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Jindal_buttons_pIJVwgnoW.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/kohinor-logo_dCVZ_Bj5xO.png" 
    ,type:"5"      },

{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Maruti-Suzuki-Logo-2011__1__YDl08njaq.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Ministry_of_Youth_Affairs_and_Sports.svg_SRCCx_5qP.png" 
    ,type:"5"      },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/omaxe_g_Ox4-TrW.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/tata-motors-logo-car-tamo-racemo-philippines-car-blue-text_8pMm8N03T.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/JHL_New_g8l9gVBwk.png" 
    ,type:"5"         },

{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/pacific_industries_logo_KZqGTtTJD.png" 
    ,type:"5"        },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/nitco_logo_eBJOH63qIs.png" 
    ,type:"5"        },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/icici_bank_logo_symbol_h76wIdXUH0.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/gail-india-vector-logo_HtC_-DFn-.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/ITDC_QOSXljJXv.png" 
    ,type:"5"     },

{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/golds-gym_ELtqLS2hm.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/central_boi_logo_kPK3babITy.png" 
    ,type:"5"      },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/som_VEe1HJdQNc.png" 
    ,type:"5"        },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Dainik_Bhaskar_Logo_PXRf1WsCA.png" 
    ,type:"5"        },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Daimler-logo_9mQ-RgVwIo.png" 
    ,type:"5"        },

{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Country_Inn__Suites_Radisson_logo.svg_jYGm3lwxQ.png" 
    ,type:"5"        },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Club_Mahindra_Holidays_logo.svg_pRoaflA92a.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/NTPC_Logo.svg_7HrAMEOE-.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/2010_Commonwealth_Games_Logo.svg_31ZvDsunDe.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/ABN-AMRO_Logo.svg_LmTxFopG12.png" 
    ,type:"5"     },

{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Hindustan_Times_logo.svg_kVHNWG0d3.png" 
    ,type:"5"      },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Bacardi-Logo_1qejkgzehR.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Bajaj-Logo_b_5p1Bxidy.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/Diet-Pepsi-Logo.svg_XP2BVW8z2f.png" 
    ,type:"5"     },
{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/samvardhana-motherson_6wLAXeFsV.png" 
    ,type:"5"     },

{
    src:"https://ik.imagekit.io/qljqw3tvn/Rashi/logo/indraprastha-gas-limited-logo_fHkuJNQk_.png" 
    ,type:"5"     },
]

export default ClientList