import { useEffect, useRef} from 'react'
import Logo from '../../components/Logo/Logo';
import "./Client.css"
import ClientList from './ClientList';

function ClientMobile({passClientFlag, btnRef, aboutBtnRef, contactBtnRef, testimonialBtnRef, footerRef, socialBtnRef}) {
  const clientRef = useRef();

  //Pop up handling
  useEffect(()=>{
    let handler = (event) => {
        if(!clientRef.current.contains(event.target) 
        && !btnRef.current.contains(event.target)
        && !aboutBtnRef.current.contains(event.target)
        && !contactBtnRef.current.contains(event.target)
        && !testimonialBtnRef.current.contains(event.target)
        && !footerRef.current.contains(event.target)
        && !socialBtnRef.current.contains(event.target)
        ){
        passClientFlag(false)
        }
    };
    document.addEventListener("mousedown", handler);

    return()=>{
    document.removeEventListener("mousedown",handler);
    }
  })

  return (
      <div className="client-grid" ref ={clientRef} >
        {ClientList.map((item,key)=>
        <Logo src={item.src} type={item.type}  key={key}/>
        )}
      </div>
  )
}

export default ClientMobile